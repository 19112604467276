// html, body, #root, ._loading_overlay_wrapper{
//   height: 100%;
// }

html,
body {
  background: #382169;
}
.backbutton{
position: absolute;
top: 2rem;
left: 2rem;}
.container {
  max-width: 1200px;
}

body {
  font-family: "Poppins", sans-serif;
}

.app-btn {
  transition: all 200ms ease-in-out;
}

.app-btn:hover {
  background: #8e68d8;
}
// .removeMem {
//   margin-left: 290px;
// }
// common
.hand {
  cursor: pointer;
}
.fs-xs {
  font-size: 10px;
}
//

.eye-icon {
  position: absolute;
  top: 40%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
  color: white;
}

.input-container {
  position: relative;
}

.input-field {
  padding-right: 30px; /* Space for the eye icon */
  width: 100%;
  height: 50px;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 0 10px;
  font-size: 16px;
  margin-bottom: 20px;
  color: white;
}

.input-field:focus {
  // outline: none;
  // border-color: #007bff;
}


.backb{
  margin-bottom: 650px;
}

h1 {
  color: #fff;
  font-weight: 700;
  font-size: 80px;
  margin: 0;
}
.custom-text-color {
  color: #8e68d8;
}
.row {
  margin: 0;
}

:focus {
  outline: none !important;
}

.signup {
  padding: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: url("./images/sign-up.png");
  height: 100%;
  position: fixed;
  background-size: cover;
  width: 100%;

  a {
    color: #fff;
    margin: 0 0 20px 0;
    display: inline-block;
  }
  h4 {
    color: #fff;
    font-size: 24px;
    font-weight: 400;
    margin-top: 0;
  }
  input {
    width: 100%;
    padding: 15px 10px;
    background: none;
    border: 1px solid #b3aeb9;
    border-radius: 4px;
    margin-bottom: 20px;
    color: #fff;
    font-size: 18px;
    font-weight: 400;
  }
  form {
    margin-top: 20px;
  }
  input.create {
    background: #8e68d8;
  }
}
.table {
  h4 {
    font-weight: 700;
    font-size: 24px;
    color: #fff;
    text-align: center;
  }

  width: 100%;
  height: 100vh;
  background-color: #381269;
}
.dashboard {
  h4 {
    font-weight: 700;
    font-size: 24px;
    color: #fff;
    text-align: center;
  }
  .dashboard-right {
    width: 100%;
    height: 100vh;
    background-color: #381269;
  }
  .menu ul li {
    float: left;
    list-style: none;
    color: #fff;
    padding: 10px 0;
    font-size: 16px;
    font-weight: 600;
  }
  .menu ul li img {
    margin: 0 10px 0 30px;
  }
  .menu ul li:hover {
    border-left: 3px solid #8e68d8;
  }
  .menu ul {
    margin: 40px 0 0 0;
    display: inline-block;
    width: 100%;
    padding: 0;
  }
  .user {
    width: 40%;
    float: right;
    text-align: right;
    border-radius: 10px;
    background: #22143f;

    padding: 8px 10px;
  }
  .user p {
    color: #6f5e91;
    font-size: 14px;
  }
  .user p span {
    color: #68d8ca;
  }
  .user img {
    border-radius: 50%;
    float: left;
    margin: 0 13px 0 0px;
  }
  .user label {
    float: left;
    font-size: 18px;
    font-weight: 600;
    color: #fff;
    margin: 0;
  }
  .user label {
    float: left;
    font-size: 18px;
    font-weight: 600;
    color: #fff;
    margin: 0;
  }

  .games-inner {
    // background: #8e68d8;
    border-radius: 10px;
    padding: 15px 0;
    width: 150px;
  }
  .games-inner p {
    margin: 5px 0 0 0;
    color: #fff;
    font-weight: 400;
    font-size: 11px;
  }
  .game {
    height: 78vh;
    background: #321e5f;
    padding: 20px 12px;
    border-radius: 10px;
    border: 1px solid #b3aeb9;
    margin: 5px 0 0 0;
    width: 30%;
  }
  .game1 {
    height: 78vh;
    background: #321e5f;
    padding: 20px 12px;
    border-radius: 10px;
    border: 1px solid #b3aeb9;
    margin: 0 0 0 30px;
    width: 55%;
  }

  .game1 .button1 {
    background-color: #f59a30;
    margin-left: 105px;
    height: 6vh;
    width: 30vh;
    font-size: 13px;
    font-weight: 900;
  }
  .game1 .view {
    font-size: 10px;
    font-weight: 400;
    text-decoration: underline;
    display: flex;
    justify-content: end;
  }

  .game1 .button2 {
    background-color: #4dca78;
    margin-left: 20px;
    height: 6vh;
    width: 30vh;
    font-size: 13px;
    font-weight: 900;
  }

  .game1 .buttonleader {
    background-color: #6f5e91;
  }
  .game1 .heading {
    font-size: 13px;
    font-weight: 600;
    // padding-left: 50px;
  }
  h5 {
    font-size: 24px;
    font-weight: 600;
    color: #fff;
  }
  .join {
    background: url("./images/invite.png");
    padding: 35px 0;
    background-repeat: no-repeat;
    position: relative;
    height: 305px;
    background-size: contain;
  }
  .join button {
    position: absolute;
    bottom: 55px;
    left: 0px;
    right: 0;
    width: 150px;
    margin: 0 auto;
    background: #8e68d8;
    color: #fff;
    font-family: poppins;
    font-weight: 600;
    border: none;
    padding: 10px 7px;
  }
  .col-md-3 {
    width: 22%;
  }
  .join h3 {
    color: #6f5e91;
    font-size: 24px;
    font-weight: 500;
  }
}

.signup h4 {
  color: #fff;
  font-size: 24px;
  font-weight: 400;
  margin-top: 0;
}

.signup input {
  width: 100%;
  padding: 12px 10px;
  background: none;
  border: 1px solid #b3aeb9;
  border-radius: 4px;
  margin-bottom: 15px;
  color: #fff;
  font-size: 18px;
  font-weight: 400;
}

.signup form {
  margin-top: 20px;
}

.signup input.create {
  background: #8e68d8;
}

.pagination-box {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination-box span {
  margin: 5px;
  padding: 8px 12px;
  cursor: pointer;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.pagination-box span.active {
  background-color: #007bff;
  color: #fff;
  border-color: #007bff;
}

.pagination-button {
  margin: 5px;
  padding: 8px 12px;
  cursor: pointer;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.dashboard h4 {
  font-weight: 700;
  font-size: 24px;
  color: #fff;
  text-align: center;
}

.dashboard .dashboard-right {
  width: 100%;
  background-color: #381269;
  padding: 40px 10px;
}

.dashboard .menu ul li {
  float: left;
  list-style: none;
  color: #fff;
  padding: 10px 0;
  font-size: 16px;
  font-weight: 600;
}

.dashboard .menu ul li img {
  margin: 0 10px 0 30px;
}

.dashboard .menu ul li:hover {
  border-left: 3px solid #8e68d8;
}

.dashboard .menu ul {
  margin: 40px 0 0 0;
  display: inline-block;
  width: 100%;
  padding: 0;
}

.dashboard .user {
  width: 45%;
  float: right;
  text-align: right;
  border-radius: 10px;
  background: #22143f;
  padding: 8px 10px;
}

.dashboard .user p {
  color: #6f5e91;
  font-size: 14px;
  float: left;
}

.dashboard .user p span {
  color: #68d8ca;
}

.dashboard .user img {
  border-radius: 50%;
  float: left;
  margin: 0 13px 0 0px;
}

.dashboard .user label {
  float: left;
  font-size: 18px;
  font-weight: 600;
  color: #fff;
  margin: 0;
}

.dashboard .user label {
  float: left;
  font-size: 18px;
  font-weight: 600;
  color: #fff;
  margin: 0;
}

.dashboard .menu {
  background: #22143f;
  float: left;
  width: 20%;
  height: 100%;
  position: fixed;
  padding: 40px 0px;
}

.dashboard .games-inner {
  // background: #8e68d8;
  // border-radius: 10px;
  padding: 15px 0;
}

.dashboard .games-inner p {
  margin: 5px 0 0 0;
  color: #fff;
  font-weight: 600;
  font-size: 11px;
}

.dashboard .games-inner button {
  margin-top: 6px;
  background-color: #8e6cd5;
  color: #fff;
  border: none;
  font-size: 11px;
  width: 100px;
  height: 25px;
}

.dashboard .game {
  background: #321e5f;
  padding: 50px 12px;
  border-radius: 15px;
  border: 1px solid #b3aeb9;
  margin: 0 15px 0 95px;
}

.dashboard h5 {
  font-size: 24px;
  font-weight: 600;
  color: #fff;
}

.dashboard .join {
  background: url("./images/invite.png");
  padding: 35px 0;
  background-repeat: no-repeat;
  position: relative;
  height: 250px;
  background-size: 100% 100%;
  text-align: center;
  margin: 0 auto;
}

.dashboard .join button {
  position: absolute;
  bottom: 35px;
  left: 0px;
  right: 0;
  width: 120px;
  margin: 0 auto;
  background: #8e68d8;
  color: #fff;
  font-family: poppins;
  font-weight: 600;
  font-size: 13px;
  border: none;
  padding: 8px 5px;
  border-radius: 5px;
}

.dashboard .col-md-3 {
  width: 22%;
}

.dashboard .join h3 {
  color: #6f5e91;
  font-size: 24px;
  font-weight: 500;
}

.login {
  background: url("./images/sign-up.png");
  padding: 50px 0;
  .login-form {
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: 2px 3px 12px #000;
    border-radius: 5px;
    padding: 50px 0;
  }
  h2 {
    font-weight: 700;
    font-size: 40px;
    color: #fff;
    text-transform: uppercase;
  }
  form {
    text-align: left;
    width: 65%;
    margin: 0 auto;
    margin-top: 30px;
  }
  h6 {
    font-weight: 600;
    font-size: 18px;
    color: #fff;
    margin: 0 0 15px 0;
  }
  .checkboxes label {
    margin: 0 20px 0 12px;
    color: #fff;
    font-weight: 300;
    font-size: 16px;
  }
  input[type="checkbox"] {
    height: 18px;
    width: 18px;
    position: relative;
    top: 3px;
  }
  .checkboxes {
    margin-bottom: 35px;
  }
  .play-btn button {
    width: 100%;
    border: none;
    background: #8e68d8;
    font-size: 18px;
    font-weight: 600;
    color: #fff;
    padding: 12px 0;
    border-radius: 5px;
  }
  .play-btn {
    text-align: center;
    margin: 50px 0 15px 0;
    display: inline-block;
    width: 100%;
  }
}

.share {
  background: #382169;
  // height: 100vh;
}
.share-box {
  background: #000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.game-play {
  background: #382169;
  padding: 40px;
  height: 100%;
  .user label {
    width: 100%;
    color: #6f5e91;
    font-size: 14px;
    font-weight: 600;
  }
  .user {
    width: 24%;
    text-align: left;
    padding: 8px 15px;
  }
  .user p {
    margin: 0;
    width: 100%;
    font-weight: 400;
    color: #fff;
    font-size: 18px;
  }
  h5 {
    font-weight: 600;
    font-size: 16px;
    color: #fff;
    margin: 5px 0 20px 0;
  }
  .game-section {
    padding-right: 170px;
  }
  img {
    float: left;
  }
  h4 {
    float: left;
    font-size: 24px;
    font-weight: 400;
    margin: 13px 0px 0 15px;
  }
  .game-box {
    background: rgba(0, 0, 0, 0.5);
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    color: #fff;
    width: 60px;
    // padding: 5px 0;
    border-radius: 5px;
    margin: 0 0 25px 0;
  }
  input,
  select {
    padding: 8px 10px;
    width: 100%;
    border-radius: 5px;
    background: none;
    color: #fff;
    border: 1px solid #b3aeb9;
  }
  .game-box-last {
    text-align: center;
    background: #ff8e26;

    padding: 9px 0;
    font-weight: 600;
    color: #fff;
    font-size: 14px;
    border-radius: 5px;
  }
}

.game-play select:focus {
  border: none;
  border: 1px solid #b3aeb9;
  background: none;
  color: #fff;
  box-shadow: inherit;
}

.game-play select option {
  color: #000;
}

.game-play .user label {
  width: 100%;
  color: #6f5e91;
  font-size: 14px;
  font-weight: 600;
}

.game-play .user {
  width: 24%;
  height: 90%;
  text-align: left;
  padding: 8px 15px;
}

.game-play .user p {
  margin: 0;
  width: 100%;
  font-weight: 400;
  color: #fff;
  font-size: 18px;
}

.game-play h5 {
  font-weight: 600;
  font-size: 16px;
  color: #fff;
  margin: 5px 0 20px 0;
}

.game-play .game-section {
  margin: 0px 0 0 0;
}

.game-play img {
  float: left;
}

.game-play h4 {
  float: left;
  font-size: 24px;
  font-weight: 400;
  margin: 13px 0px 0 15px;
}

.game-play .game-box {
  background: rgba(0, 0, 0, 0.5);
  text-align: center;
  font-size: 24px;
  font-weight: 700;
  color: #fff;
  width: 60px;
  padding: 5px 0;
  border-radius: 5px;
  margin: 0 0 25px 0;
}

.game-play input,
.game-play select {
  padding: 8px 10px;
  width: 100%;
  border-radius: 5px;
  background: none;
  color: #fff;
  border: 1px solid #b3aeb9;
}

.game-play .game-box-last {
  text-align: center;
  background: #ff8e26;
  padding: 9px 0;
  font-weight: 600;
  color: #fff;
  font-size: 14px;
  border-radius: 5px;
}

.bottom-buttons {
  background: #22143f;
  padding: 25px 40px;
  position: relative;
  z-index: 1 !important;
}

.bottom-buttons button.cancel-btn {
  border: 1px solid #b3aeb9;
  background: inherit;
  color: #fff;
  border-radius: 5px;
  font-weight: 600;
  font-size: 16px;
  padding: 10px 28px;
}

.bottom-buttons button.Submit-btn {
  border: 1px solid #8e68d8;
  background: #8e68d8;
  color: #fff;
  border-radius: 5px;
  font-weight: 600;
  font-size: 16px;
  padding: 10px 28px;
}

.score {
  background-color: rgba(0, 0, 0, 0.5);
  font-size: 14px;
  font-weight: 600;
  width: 65%;
  float: right;
  padding: 25px 20px;
  text-align: center;
  border-radius: 10px;
}

.score .total-score {
  color: #fff;
  padding: 0 0 15px 0;
  color: #fff;
}

.score .total-score span {
  color: #6f5e91 !important;
  margin: 0 5px 0 0px;
}

.score .share-btn {
  background: #4dca78;
  color: #fff;
  font-weight: 600;
  font-size: 16px;
  margin: 0 20px 0 0px;
  border: 1px solid #4dca78;
  padding: 8px 20px;
  border-radius: 5px;
  width: 100%;
}

.score .share-btn2 {
  background: #8e68d8;
  color: #fff;
  font-weight: 600;
  font-size: 16px;
  margin: 0 20px 0 0px;
  border: 1px solid #8e68d8;
  padding: 8px 20px;
  border-radius: 5px;
  width: 100%;
}

.score .challenge {
  background: #8e68d8;
  color: #fff;
  font-weight: 600;
  font-size: 16px;
  border: 1px solid #8e68d8;
  padding: 8px 10px;
  border-radius: 5px;
  position: inherit;
}

.results .result-end {
  color: #4dca78;
  margin: 5px 0 0 0;
  font-size: 14px;
}

.results .result-end.not {
  color: #e06464;
}

.multiplayer .players {
  display: inline-block;
  width: 100%;
  height: 100%;
}

.multiplayer .players-inner {
  float: left;
  cursor: pointer;
  width: 18%;
  text-transform: capitalize;
  background: #422c71;
  margin: 0 0 30px 18px;
  padding: 12px 0px;
  text-align: center;
  color: #fff;
}

.multiplayer .players-inner:hover {
  background: #ff8e26;
}

.scoregame h {
  color: #fff;
  text-align: center;
  font-size: 40px;
  font-weight: 600;
  margin: 0px 0 0px 0;
}

.scoregame .accept {
  background: #4dca78;
  color: #fff;
  font-weight: 600;
  font-size: 16px;
  padding: 8px 0;
  border-radius: 5px;
  cursor: pointer;
  &.game-btn {
    opacity: 0.5;
  }
}

.scoregame .codes {
  width: 200px;
  background: #8e68d8;
  color: #fff;
  font-weight: 600;
  font-size: 16px;
  padding: 8px 0;
  border-radius: 5px;
  cursor: pointer;
  &.game-btn {
    opacity: 0.5;
  }
}

.scoregame .reject {
  background: #e06464;
  color: #fff;
  font-weight: 600;
  font-size: 16px;
  padding: 8px 0;
  border-radius: 5px;
  cursor: pointer;
  &.game-btn {
    opacity: 0.5;
  }
}

.scoregame .accept.active,
.scoregame .reject.active {
  opacity: 1;
}

.scoregame .score-submit button {
  background: #8e68d8;
  border: none;
  font-weight: 600;
  font-size: 18px;
  color: #fff;
  padding: 12px 40px;
  margin: 30px 0 0 0;
  border-radius: 5px;
}

.scoregame .score-submit {
  text-align: right;
  margin: 0 10px 0 0px;
}

.profile {
  color: #fff;
}

// .profile .profile-inner {
//   margin: 50px 0 0 0;
// }

.profile input {
  padding: 10px;
}

.profile .profile-img {
  position: relative;
  margin: 0 0 20px 0;
}

.profile .profile-img img {
  border-radius: 50%;
  float: none;
  position: relative;
}

.profile .profile-section h6 {
  font-weight: 600;
  font-size: 20px;
}

.profile .profile-section label {
  width: 100%;
  margin: 0;
  color: #8e68d8;
  font-size: 14px;
}

.profile .profile-section label span {
  color: #68d8ca;
}

.profile .profile-section {
  background: rgba(0, 0, 0, 0.5);
  display: inline-block;
  padding: 25px 25px 100px 25px;
  border-radius: 10px;
  margin-bottom: 50px;
}

.profile .change-p {
  border-radius: inherit !important;
  position: absolute !important;
  bottom: 4px;
  margin-left: -8px;
}

.profile .t-score {
  background: url("./images/bg-profile.png");
  background-size: contain;
  padding: 20px 0;
  border-radius: 7px;
  height: 95px;
  margin: 0 0 10px 0;
}

.profile .t-score h5 {
  margin: 0;
  font-weight: 400;
  font-size: 20px;
}

.profile .margin .col-md-6 {
  margin: 0 0 15px 0;
}

.profile .t-score p {
  color: #6f5e91;
  font-size: 14px;
  font-weight: 600;
  margin: 0 0 5px 0;
}

.profile .margin {
  margin: 25px 0 0 0;
}

.profile form {
  margin-top: 150px;
}

.profile form p {
  float: left;
  width: 47%;
  margin: 0 25px 37px 0px;
}

.profile .submit {
  width: 30%;
  display: inline-block;
  margin-top: 100px;
}

.profile .submit input {
  background: #8e68d8;
  border: none;
  padding: 14px;
  font-size: 18px;
}

.challenge {
  background: url("./images/sign-up.png");
  height: 100%;
  position: fixed;
  width: 100%;
}

.challenge h4 {
  color: #fff;
}

.challenge .challenger h4 {
  color: #fff;
  margin: 0;
  width: 100%;
}

.challenge .challenge-inner {
  margin: 50px 0 0 0;
}

.challenge .challenger p {
  display: inline-block;
  width: 100%;
  text-align: center;
  color: #8e68d8;
  font-size: 18px;
  font-weight: 400;
}

.challenge .challenger span {
  color: #68d8ca;
}

.challenge .challenger-left {
  float: left;
  width: 40%;
  text-align: center;
  background: rgba(168, 168, 168, 0.1);
  border-radius: 10px;
  padding: 65px 25px;
}

.challenge .challenger-right {
  float: right;
  width: 40%;
  text-align: center;
  background: rgba(168, 168, 168, 0.1);
  border-radius: 10px;
  padding: 65px 25px;
}

.challenge .challenger-mid h2 {
  font-size: 44px;
  font-weight: 400;
}

.challenge .challenger-mid {
  width: 20%;
  float: left;
  text-align: center;
  color: #fff;
  margin: 145px 0 0 0;
}

.challenge .challenger {
  display: inline-block;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  padding: 35px;
}

.challenge .challenger img {
  float: none;
  margin-bottom: 15px;
}

.challenge .challenge-btn {
  text-align: center;
  margin-bottom: 50px;
}

.challenge .challenge-btn button {
  color: #fff;
  border: none;
  padding: 12px 22px;
  border-radius: 5px;
  font-size: 16px;
  margin: 20px 18px 18px 18px;
}

.challenge .reject {
  background: #e06464;
}

.challenge .accept {
  background: #4dca78;
}

.modal-content {
  // text-align: center;
  background: rgba(56, 33, 105, 0.9);
  // padding: 10px 0 25px 0;
}

// .modal-content .close {
//   float: right;
//   text-align: right;
//   color: #fff;
//   font-size: 40px;
//   font-weight: 300;
//   padding: 10px 20px 0 4px;
// }

.code {
  display: inline-block;
  width: 100%;
  text-align: center;
  margin: 10px 0 0px 0;
}

.code-inner {
  float: left;
  width: 13%;
  overflow: hidden;
  margin: 0 3px;
}

.code-b {
  padding-left: 138px;
}

.code-button {
  background: #8e68d8;
  width: 100px;
  border: none;
  font-weight: 600;
  font-size: 17px;
  color: #fff;
  padding: 5px 0;
  border-radius: 5px;
  margin: 10px 0 15px 5px;
}
.modal-body {
  font-weight: 400;
  font-size: 22px;
  color: #fff;

  // text-transform: uppercase;
}
.blur-5 {
  backdrop-filter: blur(5px);
}

.modal-footer {
  // border: none;
}

.code-inner input {
  height: 40px;
  text-align: center;
  width: 100%;
  background: none;
  border: 1px solid #b3aeb9;
  border-radius: 3px;
  color: #fff;
}

// .modal-footer {
//   border: none;
//   width: 70%;
//   margin-right: 3px;
//   margin: 0 auto;
//   padding-top: 0;
// }

.modal-footer button {
  background: #8e68d8;
  width: 100%;
  border: none;
  font-weight: 600;
  font-size: 17px;
  color: #fff;
  padding: 9px 0;
  border-radius: 5px;
  margin: 0 0 15px 5px;
}

// .modal-dialog {
//   max-width: 400px !important;
//   margin-top: 200px;
// }

.share-popup {
  // width: 45%;
  margin: 0 auto;
  // background: #160c29;
  text-align: center;
  padding: 25px;
  border-radius: 5px;
}

.share-popup .result-header h4 {
  float: left;
  font-size: 22px;
  color: #fff;
}

.share-popup .result-header {
  display: inline-block;
  width: 100%;
}

.share-popup .share:hover {
  background: #22163a;
  border: 1px solid #8e68d8;
}

.share-popup .result-header button {
  color: #e06464;
  font-weight: 300;
  font-size: 36px;
  margin: 0 0 8px 0;
}

.share-popup .result-header button {
  color: #e06464;
  font-weight: 300;
  font-size: 36px;
  margin: 0 0 8px 0;
  text-shadow: none;
}

.share-popup .result-header button:hover {
  color: #e06464;
}

.share-popup .share {
  float: left;
  width: 100px;
  margin: 0 10px 0 10px;
  background: #1f1733;
  padding: 15px;
  border-radius: 6px;
  text-align: center;
  min-height: 104px;
  border: 1px solid #1f1733;
}

.share-popup .share img {
  width: 50px;
  text-align: center;
  // margin: 0 auto;
}

.share-popup .share-boxes {
  display: inline-block;
}

.share-popup label {
  color: #fff;
  font-size: 12px;
  width: 100%;
  margin: 12px 0 0 0;
}

.signup.pop {
  background-image: none;
  background-color: rgba(37, 30, 49, 0.5);
  width: 40%;
  margin: 0 auto;
  height: auto;
  text-align: center;
  padding: 20px 30px;
  position: static;
}

.signup.pop h1 {
  font-size: 50px;
}

.signup.pop input {
  padding: 12px 10px;
}

.history table {
  border-collapse: collapse;
  width: 100%;
  height: 100vh;
}

.history {
  background: #381269;
}

.history table {
  border-collapse: collapse;
  width: 100%;
}

.history .user label {
  color: #fff;
  float: right;
}

.history .user {
  padding: 20px 0;
}

.history .history td {
  padding: 0 0 20px 0px;
}

.history strong {
  background: rgba(0, 0, 0, 0.5);
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  color: #fff;
  width: 60px;
  padding: 5px 20px;
  border-radius: 5px;
}

.history button {
  background: #4dca78;
  color: #fff;
  font-weight: 600;
  font-size: 16px;
  margin: 0 20px 35px 0px;
  border: 1px solid #4dca78;
  padding: 8px 20px;
  border-radius: 5px;
}

.history h {
  color: #fff;
  text-align: center;
  font-size: 40px;
  font-weight: 600;
  margin: 5px 0 5px 0;
}

.history th {
  padding: 10px 0 10px 0 !important;
}

.history td {
  padding: 10px 10 10px 0 !important;
}

.history td,
.history th {
  /* border: 1px solid #dddddd; */
  text-align: center;
  // padding: 3px;
  color: #fff;
}

.history span {
  color: red;
  cursor: pointer;
}

.history table {
  border-collapse: collapse;
  width: 100%;
}
.history {
  table {
    border-collapse: collapse;
    width: 100%;
  }
  h1 {
    color: #000;
    text-align: center;
    font-size: 40px;
    margin: 22px 0 25px 0;
  }
  td,
  th {
    border: 1px solid #dddddd;
    text-align: center;
    padding: 8px;
  }
}

.history h1 {
  color: #000;
  text-align: center;
  font-size: 40px;
  margin: 22px 0 25px 0;
}

.history td,
.history th {
  border: 1px solid #dddddd;
  text-align: center;
  padding: 8px;
}

.MultiPlayerHeading {
  color: #fff;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.disable {
  opacity: 0.5;
  pointer-events: none;
}

.new-group {
  margin-top: 50px;
  margin-bottom: 50px;
  .start-group button {
    background: #4dca78;
    color: #fff;
    border: none;
    font-size: 18px;
    font-weight: 500;
    padding: 10px 25px;
    border-radius: 4px;
  }
  .text-right {
    text-align: right;
  }
  .group-outer {
    margin-top: 30px;
  }
  .group-outer {
    margin-top: 30px;
    background: #32215e;
    border: 1px solid #777;
    border-radius: 5px;
    padding: 50px 10px;
    height: 500px;
    overflow-x: hidden;
    overflow-y: scroll;
  }
  .viewGame {
    background: #382169;
    padding: 28px 20px;
    border: 1px solid #666;
    border-radius: 5px;
    height: 345px;
    overflow-y: hidden;
    overflow-x: scroll;
  }

  .col-md-6.start-group {
    padding: 0;
  }
  .group-button .btn-right {
    background: #8f5e91;
    border: none;
    font-size: 12px;
    color: #fff;
    border-radius: 4px;
    padding: 7px 20px;
  }
  .group-text-top label {
    color: #fff;
    font-size: 16px;
    font-weight: 500;
  }
  .group-button .btn-left {
    background: #8e68db;
    border: none;
    font-size: 12px;
    color: #fff;
    margin: 0 8px 0 0px;
    border-radius: 4px;
    padding: 7px 20px;
  }
  .group-button img {
    width: 18px;
  }
  .group-box i {
    color: #fff;
    font-size: 12px;
    font-weight: 400;
    border: 1px solid #888;
    padding: 3px 6px;
    border-radius: 2px;
    cursor: pointer;
    margin: 0 0 0 5px;
  }
  .group-text-top {
    margin: 0 0 15px 0;
  }
  .group-box {
    border: 1px solid #666;
    min-height: 260px;
    background: #382169;
    padding: 35px 0;
    border-radius: 4px;
  }
  .group-btn-bottom {
    margin: 15px 0 0 0;
  }
  .group-btn-bottom button {
    background: none;
    font-size: 11px;
    font-weight: 500;
    margin: 0 5px;
    padding: 2px 16px;
    border-radius: 2px;
    cursor: pointer;
    border: 1px solid #fff;
    color: #fff;
  }
  .group-margin {
    margin: 20px 0 0 0;
  }
  /* width */
  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #d9d9d9;
    border-radius: 5px;
  }
}

.g-member {
  width: 90%;
  height: 200px;
  margin: 0 auto;
  background: #382169;
  border: 1px solid #777;
  border-radius: 5px;
  padding: 10px;
  // margin-bottom: 100px;
  .cross {
    width: 100%;
    margin: 0 0 0 0;
    text-align: right;
  }
  h2 {
    color: #fff;
    font-size: 26px;
    text-align: center;
    font-weight: 600;
    margin: 0 0 25px 0;
  }
  .members img {
    width: 100%;
  }
  .add {
    border: 1px solid #777;
    border-radius: 10px;
    margin: 0 0 0 15px;
  }
  .member {
    float: left;
    margin: 0 7px;
  }
  .addmember {
    margin-left: 87px;
  }
  .members {
    display: inline-block;
    width: 100%;
    padding: 0 30px;
  }
  .member label {
    color: #fff;
    margin: 8px 0 0 0;
    font-size: 13px;
    width: 800px;
  }
  label.trash {
    float: right;
    color: red;
  }
  label.remove-members {
    font-size: 12px !important;
    padding: 1px 8px;
    width: 100px;
    cursor: pointer;
  }
  .members-vertical {
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: scroll;
    background: #32215e;
    border-radius: 10px;
    padding: 10px 5px;
    height: 170px;
  }
  .newmember{
    width: 300px;
  }

  .members-vertical::-webkit-scrollbar {
    width: 4px;
  }
  .members-vertical::-webkit-scrollbar-thumb {
    background-color: white;
    border-radius: 10px;
  }

  .members-vertical::-webkit-scrollbar-track {
    background-color: #32215e;
    border-radius: 10px;
  }

  .member.add {
    margin-left: 110px;
    text-align: center;
    min-height: 51px;
    width: 150px;
    padding-top: 12px;
   margin-bottom: 20px;
  }
  .member.add img {
    width: 200px;
  }
}
.group-detail {
  margin-left: 470px;
  background: none;
  h2 {
    color: #fff;
    font-size: 22px;
    font-weight: 400;
    margin: 0;
  }
  .cross {
    width: 100%;
  }
  .cross img {
    float: right;
    margin: 8px 0 0 0;
    width: 15px;
  }
  .signup.pop {
    display: inline-block;
    width: 60%;
    padding: 30px;
    border-radius: 5px;
  }
  .signup.pop input {
    padding: 12px 10px;
    text-align: center;
    font-size: 14px;
    border: 1px solid #8e68d8;
  }
  .group-inner {
    background: #383169;
    border-radius: 5px;
    padding: 16px 80px;
    margin: 0 auto;
    margin-top: 25px;
  }
  .g-name ::-ms-input-placeholder {
    /* Edge 12-18 */
    color: #fff;
    font-weight: 500;
  }
  .group-inner form button {
    background: none;
    border: 1px solid #f1f1f1;
    width: 100%;
    border-radius: 5px;
    padding: 12px 0;
    color: #fff;
    font-size: 12px;
    cursor: pointer;
  }
  .group-inner form button img {
    width: 20px;
  }
  .row.form-detail {
    margin: 10px -12px;
  }
  .form-detail input {
    border: 1px solid #fff !important;
  }
  button.submit {
    background: #4dca78 !important;
    font-size: 16px !important;
  }
  button.add-player {
    background: #5a3d95 !important;
    font-size: 16px !important;
    margin-bottom: 15px;
  }
  .g-name ::placeholder {
    color: #fff;
    font-weight: 500;
  }
}

.group-name {
  .text-right {
    text-align: right;
  }
  .groupname-top h2 {
    color: #fff;
    font-weight: 800;
    font-size: 24px;
  }
  .groupname-top i {
    font-weight: 300 !important;
    color: #fff;
    font-size: 12px;
    border: 1px solid #888;
    padding: 3px 6px;
    border-radius: 2px;
    cursor: pointer;
    margin: 0 0 0 5px;
  }
  .groupname-top button {
    background: #4dca78;
    color: #fff;
    border: none;
    font-size: 18px;
    font-weight: 500;
    padding: 10px 25px;
    border-radius: 4px;
  }
  .game-history {
    background: #32215e;
    padding: 25px 15px;
    border-radius: 5px;
    border: 1px solid #666;
    margin: 55px 0 0 0;
  }
  .game-history h2 {
    color: #fff;
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    margin: 0 0 25px 0;
  }
  .history-box-left {
    transition: all 200ms ease;
  }
  
  .history-box-left:hover {
    background: #2a1857;

    
  }
  .history-box-left.active{
    background: #2a1857;
  }


  .history-box-left img {
    width: 32px;
    margin: 0 5px 0 0px;
  }
  .history-box-left span {
    color: #fff;
  }
  .history-box-left {
    float: left;
    width: 90%;
    height: 120px;
    padding: 21px 12px;
   
    border: 1px solid #666;
  }
  .history-box-right {
    float: right;
    // width: 50%;
    text-align: right;
  }
  .history-box-right button {
    background: #5c3f98;
    width: 100px;
    border: none;
    color: #fff;
    padding: 8px 33px;
    border-radius: 5px;
    font-size: 14px;
  }
  .history-box {
    border: 1px solid #777;
    display: inline-block;
    width: 100%;
    padding: 8px 15px;
    margin: 0 0 15px 0;
    border-radius: 5px;
    background: #382169;
  }
  .history-box-right img {
    width: 20px;
  }
  .history-left {
    background: #382169;
    padding: 28px 20px;
    border: 1px solid #666;
    border-radius: 5px;
    height: 345px;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  ::-webkit-scrollbar {
    width: 3px;
  }

  .cross {
    margin-right: 10px;
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #d9d9d9;
    border-radius: 5px;
  }
  table {
    width: 100%;
    height: 345px;
    background: #382169;
    padding: 15px 0;
    border: 1px solid #666;
    border-radius: 8px !important;
    display: inline-block;
    // overflow-y: hidden;
    overflow-x: auto;
    
  }
  table tr {
    width: 100%;
    display: inline-block;
    padding: 10px 0px;
  }
  th {
    color: #fff;
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    margin: 0 0 25px 0;
  }
  tr:nth-child(even) {
    background-color: #32215e;
  }
  td {
    text-align: center;
    color: #fff;
    font-size: 14px;
    width: 22%;
    display: inline-block;
  }
  span {
    color: #e72323;
    cursor: pointer;
  }
}

.history-pop {
  width: 100%;
  margin: 0 auto;
  text-align: center;
  border: 0px solid #777;
  border-radius: 5px;
  padding: 25px 30px;
  img {
    float: right;
    width: 15px;
  }

  .blur-5 {
    backdrop-filter: blur(5in);
  }
  h3 {
    color: #fff;
    font-size: 22px;
    // padding: 0 90px;
    margin-top: 45px;
  }
  .buttons-pop button {
    color: #fff;
    padding: 5px 40px;
    border-radius: 5px;
    margin: 20px 5px 5px 5px;
  }
  button.btn-1 {
    background: #32215e;
    border: 1px solid #666;
  }
  button.bt-2 {
    background: #8e68d8;
    border: 1px solid #8e68d8;
  }
}

.group-name.leaderboard {
  .btn-play button {
    background: #5c3f98 !important;
    border: none;
    color: #fff;
    // padding: 10px 46px !important;
    border-radius: 5px;
  }
  .history-box.calendar {
    border-top: none !important;
    border-left: none;
    border-right: none;
    border-radius: 0;
    margin: 0;
  }
  .text-center {
    text-align: center;
  }
  .history-box.calendar h2 {
    margin-bottom: 0;
    padding: 15px 0;
    font-weight: 400;
    text-align: left;
  }
  .history-left {
    margin-top: 49px !important;
  }
  .history-box.calendar h2 i {
    float: right;
  }
}

.play-friends {
  margin-bottom: 80px;
  h2 {
    color: #fff;
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    margin: 0 0 25px 0;
  }
  .play-friends-left {
    background: #32215e;
    padding: 30px 20px;
    border: 1px solid #666;
    border-radius: 5px;
    display: inline-block;
    width: 100%;
  }
  .play-friends-left span {
    color: #fff;
    font-size: 14px;
    width: 100%;
    display: inline-block;
  }
  .play-friends-left .game-left.game-left {
    width: 48%;
    float: left;
    text-align: center;
    border-radius: 5px;
    background-image: url("");
    background-size: cover;
    padding: 25px 0;
    min-height: 185px;
    background-position: 0 0;
  }
  .play-friends-left button {
    background: #5c3f98;
    border: none;
    color: #fff;
    padding: 7px 20px;
    border-radius: 5px;
    font-size: 12px;
    margin: 12px 0 0 0;
  }
  .game-right {
    float: right;
    width: 48%;
    text-align: center;
    background-image: url("");
    border-radius: 5px;
    padding: 25px 0;
    background-size: cover;
    min-height: 185px;
    background-position: 0px -4px;
  }
  .play-friends-left button img {
    width: 14px;
  }
  .play-friends-left img {
    width: 50px;
  }
  .play-friends-right {
    background: #32215e;
    padding: 30px 20px;
    border: 1px solid #666;
    border-radius: 5px;
    display: inline-block;
    width: 100%;
  }
  .play-btn button {
    width: 43%;
    margin: 0 15px;
    border: none;
    padding: 15px 2px;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
  }
  .play-btn {
    display: inline-block;
    width: 100%;
    text-align: center;
  }
  button.all-group-btn {
    background: #f59a30;
  }
  button.new-group-btn {
    background: #4dca78;
  }
  .groups h4 {
    color: #fff;
    font-size: 18px;
    float: left;
  }
  .groups a {
    float: right;
    color: #fff;
    font-size: 14px;
  }
  .groups-inner {
    float: left;
    width: 30%;
    text-align: center;
    background: #382169;
    border: 1px solid #666;
    border-radius: 5px;
    margin: 0 20px 0 0px;
  }
  .group-outer {
    display: inline-block;
    width: 100%;
    margin: 35px 0 0 0;
  }
  .group-outer {
    display: inline-block;
    width: 94%;
    margin: 0 36px;
    margin-top: 20px;
  }
  button.play {
    background: #5c3f98;
  }
  .groups-inner button.groups-inner button {
    width: 75%;
    margin: 0 0 10px 0;
    font-size: 12px;
    color: #fff;
    border: none;
    border-radius: 3px;
    padding: 7px 0;
    font-weight: 400;
  }
  button.leader {
    background: #6f5e91;
  }
  .groups-inner button img {
    width: 17px;
  }
  .group-text h5 {
    font-size: 14px;
  }
  .group-text a {
    float: none;
    margin: 0 0 0 5px;
    font-size: 10px !important;
  }
  .group-text {
    color: #fff;
    text-align: center;
    width: 100%;
    margin: 4px 0 15px 0;
  }
  .active-groups {
    display: inline-block;
    width: 90%;
    margin: 25px 36px 0 36px;
  }

  .groups-inner button {
    border: none;
    width: 80%;
    border-radius: 5px;
    padding: 5px 0;
    color: #fff;
    margin: 0 0 14px 0;
    font-weight: 400;
    font-size: 14px;
  }
}


.Historybutton{
  width: 100px;
  background-color: #8E68D8;
}
button.btn.btn-close{
  padding: 0px;
  --bs-btn-close-bg: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23ffffff%27%3e%3cpath d=%27M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z%27/%3e%3c/svg%3e');
margin: 0;
margin-left: calc(100% - 70%);
border:none !important;
}
.modal-header button{
  width: 20px !important;
  background-color: transparent !important;
}
.modal-dialog-centered .modal-body{
  padding:4% 7%;
}
.updatebutton{
  margin-left: 350px;
}
.signup.pop.invite-a-friend {
  background-image: none;
  background-color: transparent !important;
  
  margin: 0 auto;
  height: auto;
  text-align: center;
  padding: 20px 30px;
  position: static;
  input{
    margin-left: 10px;
    }
      ::placeholder{
        color: white;
      }
  .icon{
    height: 40px;
    width: 40px;
    min-width: 40px;
    background-color:#8e68db;
    float: left;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  span{
    font-size: 14px;
  }
  .add_btn{
    background: #8e68d8;
    color: #fff;
    font-family: poppins;
    font-weight: 400;
    font-size: 18px;
    border: none;
    height: 50px;
    border-radius: 5px;
    &:hover{
      background: #6945af;

    }
  }
}